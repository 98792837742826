import { fork } from 'redux-saga/effects';
import { metamaskSagas } from './metamaskSagas';
import { stakeSagas } from './stakeSagas';
import { balanceSagas } from './balanceSagas';

export const walletEffects = [
  fork(metamaskSagas),
  fork(stakeSagas),
  fork(balanceSagas),
];
