import {
  WalletState,
  StakeDepositPayload,
  WithdrawPayload,
  StakeStatus,
} from './types';
import {
  WalletActionType,
} from './actionTypes';

export const walletSetState = (payload: Partial<WalletState>) => ({
  type: WalletActionType.SET_STATE,
  payload,
});

export const walletOnAppMount = () => ({
  type: WalletActionType.ON_APP_MOUNT,
});

export const walletConnect = () => ({
  type: WalletActionType.CONNECT,
});

export const walletDisconnect = () => ({
  type: WalletActionType.DISCONNECT,
});

export const walletStakeDeposit = (payload: StakeDepositPayload) => ({
  type: WalletActionType.STAKE_DEPOSIT,
  payload,
});

export const walletWithdrawDeposit = (payload: WithdrawPayload) => ({
  type: WalletActionType.WITHDRAW_DEPOSIT,
  payload,
});

export const walletWithdrawRewards = (payload: WithdrawPayload) => ({
  type: WalletActionType.WITHDRAW_REWARDS,
  payload,
});

export const walletGetUserStakes = (payload: { status: StakeStatus }) => ({
  type: WalletActionType.GET_USER_STAKES,
  payload,
});

export const walletGetTokensBalance = () => ({
  type: WalletActionType.GET_TOKENS_BALANCE,
});
