import React, { useContext } from 'react';
import cx from 'classnames';
import { Outlet, useLocation } from 'react-router-dom';
import { Footer } from 'components';
import { HeaderContainer } from 'containers';
import { ModalLayer } from 'layouts';
import { PathName } from 'global';
import { ModalContext } from '@project/libs/context';
import styles from './styles.module.scss';

export const MainLayout = () => {
  const { isModalOpen } = useContext(ModalContext);
  const { pathname } = useLocation();

  const isHomeButtonsMobileBottomPadding = pathname === PathName.home;

  return (
    <div
      className={cx(
        styles.wrapper,
        { [styles.rigth_padding]: isModalOpen },
        { [styles.bottom_padding]: isHomeButtonsMobileBottomPadding },
      )}
    >
      <HeaderContainer />
      <Outlet />
      <Footer />
      <ModalLayer />
    </div>
  );
};
