import React, {
  memo,
  useState,
  useCallback,
  useMemo,
  ReactElement,
} from 'react';
import cx from 'classnames';
import { Text } from '@project/libs/components';
import styles from './styles.module.scss';

type TabsProps = {
  tabNames: string[];
  tabContents: ReactElement[];
  className?: string;
} ;

const Tabs = memo<TabsProps>(({
  tabNames,
  tabContents,
  className,
}) => {
  const [tabIndex, setTabIndex] = useState(0);

  const onTabClick = useCallback((index: number) => () => {
    setTabIndex(index);
  }, []);

  const borderWidthPercantage = useMemo(() => (100 / tabNames.length), [tabNames.length]);

  const borderBottomStyle = useMemo(() => (
    {
      width: `${borderWidthPercantage}%`,
      left: `${borderWidthPercantage * tabIndex}%`,
    }), [borderWidthPercantage, tabIndex]);

  return (
    <div className={cx(styles.tabs_container, className)}>
      <div className={styles.tabs_header}>
        {tabNames.map((tabName, index) => (
          <button
            key={tabName}
            className={styles.tab_name_container}
            onClick={onTabClick(index)}
          >
            <Text
              type="h4"
              className={cx(styles.tab_title, {
                [styles.tab_title_active]: index === tabIndex,
              })}
            >
              {tabName}
            </Text>
          </button>
        ))}
        <div
          className={styles.border_line}
          style={borderBottomStyle}
        />
      </div>
      <div className={styles.tabs_content}>
        {tabContents.map((tabContent, index) => (
          <div
            className={cx(styles.single_tab_content, {
              [styles.single_tab_content_active]: index === tabIndex,
            })}
            key={tabNames[index]}
          >
            {tabContent}
          </div>
        ))}
      </div>
    </div>
  );
});

export { Tabs };
