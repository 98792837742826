import React from 'react';
import { Route, Routes as RoutesDom } from 'react-router-dom';
import { PathName } from 'global';
import { useScrollToTop } from 'hooks';
import { MainLayout } from 'layouts';
import { HomePage, StakePage } from 'pages';

export const Routes = () => {
  useScrollToTop();
  return (
    <RoutesDom>
      <Route
        path={PathName.home}
        element={<MainLayout />}
      >
        <Route
          path={PathName.home}
          element={<HomePage />}
        />
        <Route
          path={PathName.stake}
          element={<StakePage />}
        />
      </Route>
    </RoutesDom>
  );
};
