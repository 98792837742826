export const hollandStakingAddress = process.env.REACT_APP_HOLLAND_STAKING_ADDRESS as string;

export const tokenAddress = process.env.REACT_APP_HOLLAND_TOKEN_ADDRESS as string;

export const decimalPlaces = 18;

export const passwordSign = 'passwordSign';

export const tokenPurchaseUrl = 'https://gaiaworld.com/gaiatoken';

export enum ScreenWidth {
  tablet = 768,
  mobile = 576,
}
