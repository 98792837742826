import React, {
  memo,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import { useSelector } from 'react-redux';
import { strings, Color } from 'global';
import { walletSelectors } from 'store/wallet/selectors';
import { Button, Text } from '@project/libs/components';
import { ModalContext } from '@project/libs/context/modalContext';
import { differenceBetweenDate, formatDate } from 'utils';
import { UserStake } from 'types';
import { RowItem, RowsTable } from './RowsTable';
import styles from './styles.module.scss';

type ModalUnstakeConfirmationProps = {
  userStake: UserStake;
  onWithdrawDeposit: (index: number, callback: () => void) => void;
};

const penaltyComissionShare = 0.75;

const ModalUnstakeConfirmation = memo<ModalUnstakeConfirmationProps>(({
  userStake,
  onWithdrawDeposit,
}) => {
  const { closeModal } = useContext(ModalContext);

  const {
    stakeSize,
    stakeIndex,
    dueDate,
    rewardAvailable,
    rewardsClaimed,
    totalRewards,
  } = userStake;

  const isUnstaking = useSelector(walletSelectors.getProp('isUnstaking'));

  const isOverDueDate = new Date(dueDate) < new Date();

  const penaltySize = useMemo(() => {
    if (isOverDueDate) {
      return 0;
    }
    return Math.ceil(stakeSize * penaltyComissionShare);
  }, [isOverDueDate, stakeSize]);

  const rowsTopWithPenalty: RowItem[] = useMemo(() => [
    {
      title: strings.stakedTokens,
      value: stakeSize.toString(),
    },
    {
      title: strings.rewardsAvailable,
      value: rewardAvailable.toString(),
    },
    {
      title: `${strings.penaltySize} (${penaltyComissionShare * 100}%)`,
      value: `-${penaltySize}`,
    },
  ], [penaltySize, rewardAvailable, stakeSize]);

  const rowsTopWithoutPenalty: RowItem[] = useMemo(() => [
    {
      title: strings.stakedTokens,
      value: stakeSize.toString(),
    },
    {
      title: strings.totalRewards,
      value: totalRewards.toString(),
    },
    {
      title: strings.rewardsClaimedEarlier,
      value: rewardsClaimed.toString(),
    },
    {
      title: strings.rewardsAvailable,
      value: rewardAvailable.toString(),
    },
  ], [rewardAvailable, rewardsClaimed, stakeSize, totalRewards]);

  const receiveValueSize = useMemo(
    () => (stakeSize + rewardAvailable - penaltySize),
    [penaltySize, stakeSize, rewardAvailable],
  );

  const rowsBottom: RowItem[] = useMemo(() => [
    {
      title: strings.youWillReceive,
      value: receiveValueSize.toString(),
    },
  ], [receiveValueSize]);

  const onCancelClick = useCallback(() => {
    closeModal();
  }, [closeModal]);

  const onConfirmUnstakeClick = useCallback(() => {
    onWithdrawDeposit(stakeIndex, closeModal);
  }, [closeModal, onWithdrawDeposit, stakeIndex]);

  const stakingTimeCountdown = differenceBetweenDate(formatDate(dueDate));

  return (
    <div className={styles.unstake_confirmation_container}>
      <Text
        type="h2"
        className={styles.unstake_confirmation_title}
      >
        {strings.unstakeConfirmation}
      </Text>
      { !isOverDueDate && (
      <Text
        type="span"
        className={styles.unstake_confirmation_warning}
      >
        {strings.warning}
      </Text>
      )}
      {!isOverDueDate && (
      <Text
        type="span"
        className={styles.unstake_confirmation_description}
      >
        {strings.youAreUnstakingTheActivePosition}
        <br />
        {`${strings.theStakingWillBeCompletedOnlyIn} ${stakingTimeCountdown}`}
        <br />
        {`${strings.unstakingYourPositionNowWillRequireThe} ${
          penaltyComissionShare * 100
        }% ${strings.penaltyPayment}`}
      </Text>
      )}
      {isOverDueDate && (
        <Text
          type="span"
          className={styles.unstake_confirmation_description}
        >
          {strings.congratulationnsTheLockPeriodIsOver}
        </Text>
      )}
      <RowsTable
        rows={isOverDueDate ? rowsTopWithoutPenalty : rowsTopWithPenalty}
      />
      <RowsTable
        rows={rowsBottom}
        className={styles.unstake_confirmation_bottom_rows_table}
      />
      <div className={styles.unstake_confirmation_buttons_container}>
        <Button
          color={Color.grey}
          edgingColor={Color.black5}
          className={styles.unstake_confirmation_button}
          onClick={onCancelClick}
        >
          {strings.cancel}
        </Button>
        <Button
          edgingColor={Color.black5}
          className={styles.unstake_confirmation_button}
          isLoading={isUnstaking}
          onClick={onConfirmUnstakeClick}
        >
          {strings.confirm}
        </Button>
      </div>
    </div>
  );
});

export { ModalUnstakeConfirmation };
