import React, { memo } from 'react';
import cx from 'classnames';
import { Text } from '@project/libs/components';
import styles from './styles.module.scss';

export type RowItem = {
  title: string;
  value: string;
};

type RowsTableProps = {
  rows: RowItem[],
  className?: string,
};

const RowItemComponent = memo<RowItem>(({ title, value }) => (
  <div className={styles.rows_item_container}>
    <Text
      className={styles.row_item_title}
      type="span"
    >
      {title}

    </Text>
    <Text
      type="span"
      className={styles.row_item_value}
    >
      {value}

    </Text>
  </div>
));

const RowsTable = memo<RowsTableProps>(({ rows, className }) => (
  <div className={cx(styles.rows_table_container, className)}>
    {rows.map(({ title, value }) => (
      <RowItemComponent
        title={title}
        value={value}
        key={value}
      />
    ))}
  </div>
));

export { RowsTable };
