import { Text, Button } from '@project/libs/components';
import React, {
  memo,
  useCallback,
} from 'react';
import { strings, Color } from 'global';
import { walletConnect } from 'store/wallet/actionCreators';
import { useDispatch } from 'react-redux';
import styles from './styles.module.scss';

const WalletNotConnected = memo(() => {
  const dispatch = useDispatch();
  const onWalletConnectClick = useCallback(() => {
    dispatch(walletConnect());
  }, [dispatch]);
  return (
    <div className={styles.walletnotconnect_container}>
      <Text
        type="h1"
        className={styles.walletnotconnect_container_title}
      >
        {strings.stakeTitle}
      </Text>
      <Text
        type="p"
        className={styles.walletnotconnect_container_desciption}
      >
        {strings.connect_your_wallet}
      </Text>
      <Button
        edgingColor={Color.grey}
        className={styles.connect_button}
        onClick={onWalletConnectClick}
      >
        {strings.connectWalletButton}
      </Button>
    </div>
  );
});

export { WalletNotConnected };
