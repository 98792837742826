import React, {
  ChangeEvent,
  memo,
  useCallback,
} from 'react';
import cx from 'classnames';
import { useSetFocus } from '@project/libs/hooks';
import styles from './styles.module.scss';

type TextInputProps = {
  value: string;
  defaultValue?: string;
  name?: string;
  isPassword?: boolean;
  label?: string;
  classNameInput?: string;
  classNameLabel?: string;
  classNameContainer?: string;
  disabled?: boolean;
  isWithClear?: boolean;
  onChangeValue?: (text: string, name?: string) => void;
  placeholder?: string;
  isTextOnly?: boolean;
  isNumberOnly?: boolean;
};

export const TextInput = memo<TextInputProps>(({
  value,
  defaultValue,
  name,
  label,
  classNameInput,
  classNameLabel,
  classNameContainer,
  disabled = false,
  onChangeValue,
  placeholder = '',
  isNumberOnly,
}) => {
  const { ref, setFocus } = useSetFocus();

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (onChangeValue !== undefined) {
      onChangeValue(e.target.validity.valid ? e.target.value : value, name);
    }
  }, [onChangeValue, value, name]);

  const pattern = isNumberOnly ? '[0-9]*' : undefined;

  return (
    <div className={cx(styles.input__container, classNameContainer)}>
      {label && (
        <div
          className={cx(styles.input__label, classNameLabel)}
          onClick={setFocus}
          onKeyPress={undefined}
          role="button"
          tabIndex={0}
        >
          {label}
        </div>
      )}
      <div className={cx(styles.input__box)}>
        <input
          ref={ref}
          pattern={pattern}
          name={name}
          value={value}
          type="text"
          className={classNameInput}
          disabled={disabled}
          defaultValue={defaultValue}
          onChange={handleChange}
          placeholder={placeholder}
        />
      </div>
    </div>
  );
});
