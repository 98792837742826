import { createReducer } from 'utils';
import { WalletState, WalletStatus } from './types';
import { walletHandlers } from './handlers';

export const walletInitialState: Readonly<WalletState> = {
  isStakingInProcess: false,
  isUnstaking: false,
  isWithdrawingRewards: false,
  isTokensBalanceLoading: false,
  isUserStakesLoading: false,

  address: undefined,
  status: WalletStatus.INIT,
  balance: 0,
  network: null,

  tokensBalance: undefined,

  userStakes: [],
};

export default createReducer(walletInitialState, walletHandlers);
