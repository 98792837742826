import { NetworkName } from 'global';

export enum WalletStatus {
  INIT = 'INIT',
  AVAILABLE = 'AVAILABLE',
  NOT_AVAILABLE = 'NOT_AVAILABLE',
  CONNECTED = 'CONNECTED',
  LOST = 'LOST',
  NOT_SUPPORT = 'NOT_SUPPORT',
  LOADING = 'LOADING',
}

export enum MetamaskRequestMethod {
  eth_requestAccounts = 'eth_requestAccounts',
  eth_accounts = 'eth_accounts',
  eth_chainId = 'eth_chainId',
  wallet_switchEthereumChain = 'wallet_switchEthereumChain',
  wallet_addEthereumChain = 'wallet_addEthereumChain',
}

export enum Web3Event {
  disconnect = 'disconnect',
  connect = 'connect',
  accountsChanged = 'accountsChanged',
  chainChanged = 'chainChanged',
}

export type WalletState = {
  isStakingInProcess: boolean,
  isUnstaking: boolean,
  isWithdrawingRewards: boolean,
  isTokensBalanceLoading: boolean,
  isUserStakesLoading: boolean,

  address?: string,
  status: WalletStatus,
  balance: number,
  network: NetworkName | null,

  tokensBalance?: string,

  userStakes: UserStake[],
};

export type StakeDepositPayload = {
  amount: string;
  stakingPeriod: number;
  callback?: () => void;
};

export type WithdrawPayload = {
  stakeIndex: number;
  callback?: () => void;
};

export enum StakeStatus {
  active = 'active',
  unstaked = 'unstaked',
  combined = 'combined',
}

export type UserStakeRaw = {
  id: number;
  createdAt: string;
  updatedAt: string;
  lastBlock: number;
  deletedAt: string;
  address: string;
  transactionHash: string;
  blockNumber: number;
  stakeDate: string;
  stakeIndex: number;
  stakeStatus: StakeStatus;
  stakeSize: number;
  dueDate: string;
  rewardAvailable: string;
  rewardsClaimed: string;
  totalRewards: string;
  rewardClaimingDate: string;
};

export type UserStake = {
  id: number;
  stakeDate: string;
  dueDate: string;
  rewardClaimingDate: string;
  stakeIndex: number;
  stakeSize: number;
  rewardAvailable: number;
  rewardsClaimed: number;
  totalRewards: number;
  stakeStatus: StakeStatus;
};
