import React, {
  CSSProperties,
  memo,
  useCallback,
  useMemo,
} from 'react';
import { strings, Color } from 'global';
import {
  formatDate,
  formatAmountToCurrency,
  formatTimeCountdown,
} from 'utils';
import { StakeStatus, UserStake } from 'types';
import { Button, Text } from '@project/libs/components';
import styles from './styles.module.scss';

type MyStakeItemProps = {
  userStake: UserStake;
  style: CSSProperties;
  onUnstake: (userStake : UserStake) => void;
  onWithdrawRewards: (id: number) => void;
};

const MyStakeItem = memo<MyStakeItemProps>(
  ({
    userStake,
    style,
    onUnstake,
    onWithdrawRewards,
  }) => {
    const {
      stakeIndex,
      stakeDate,
      dueDate,
      rewardClaimingDate,
      stakeSize,
      rewardAvailable,
      stakeStatus,
      totalRewards,
    } = userStake;
    const stakeDateFormatted = useMemo(
      () => formatDate(stakeDate),
      [stakeDate],
    );
    const dueDateFormatted = useMemo(() => formatDate(dueDate), [dueDate]);

    const { timeCountdown } =
      formatTimeCountdown(rewardClaimingDate);

    const stakeSizeFormatted = useMemo(
      () => `${formatAmountToCurrency(stakeSize)} ${strings.tokens}`,
      [stakeSize],
    );

    const isRewardClaimingDateInFuture = useMemo(
      () => new Date(rewardClaimingDate) > new Date(),
      [rewardClaimingDate],
    );

    const isClaimRewardsDisabled = useMemo(
      () => isRewardClaimingDateInFuture || rewardAvailable <= 0,
      [isRewardClaimingDateInFuture, rewardAvailable],
    );

    const tableContainers = useMemo(
      () => [
        { label: strings.stakeDate, bodyValue: stakeDateFormatted },
        { label: strings.dueDate, bodyValue: dueDateFormatted },
        { label: strings.rewardsAvialable, bodyValue: rewardAvailable },
        { label: strings.totalRewards, bodyValue: totalRewards },
      ],
      [dueDateFormatted, rewardAvailable, stakeDateFormatted, totalRewards],
    );

    const onUnstakeClick = useCallback(() => {
      onUnstake(userStake);
    }, [onUnstake, userStake]);

    const onClaimRewardsClick = useCallback(() => {
      onWithdrawRewards(stakeIndex);
    }, [stakeIndex, onWithdrawRewards]);

    return (
      <div style={style}>
        <div
          className={styles.mystake_item_container}
        >
          <Text
            type="p"
            className={styles.stake_size}
          >
            {strings.stakeSize}
            <Text type="span">{stakeSizeFormatted}</Text>
          </Text>

          <div className={styles.mystake_item_container_table}>
            {tableContainers.map(({ label, bodyValue }) => (
              <div
                className={styles.mystake_item_row}
                key={label}
              >
                <Text
                  type="p"
                  className={styles.mystake_item_container_header_cell}
                >
                  {label}
                </Text>
                <Text
                  type="p"
                  className={styles.mystake_item_container_body_cell}
                >
                  {bodyValue}
                </Text>
              </div>
            ))}
          </div>
          {stakeStatus !== StakeStatus.unstaked && (
            <div className={styles.mystake_item_container_buttons}>
              <Button
                isSimple
                className={styles.mystake_item_container_button_unstake}
                onClick={onUnstakeClick}
              >
                {strings.unstake_button}
              </Button>
              <Button
                isSimple
                color={Color.black2}
                disabled={isClaimRewardsDisabled}
                onClick={onClaimRewardsClick}
                className={styles.mystake_item_container_button_claim}
              >
                {strings.claim_rewards_button}
              </Button>
            </div>
          )}

          {stakeStatus !== StakeStatus.unstaked && isRewardClaimingDateInFuture && (
            <div className={styles.mystake_item_container_rewards_info}>
              {strings.nextRewards}
            &nbsp;
              <Text type="span">{timeCountdown}</Text>
            </div>
          )}
        </div>
      </div>
    );
  },
);
export { MyStakeItem };
