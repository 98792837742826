import { EventChannel, eventChannel } from 'redux-saga';
import {
  call,
  put,
  select,
  spawn,
  takeLatest,
} from 'redux-saga/effects';

import { MetaMaskInpageProvider } from '@metamask/providers';

import detectEthereumProvider from '@metamask/detect-provider';
import {
  MetamaskRequestMethod,
  Unwrap,
  WalletState,
  WalletStatus,
  Web3Event,
} from 'types';
import {
  NetworkName,
} from 'global';
import {
  getMetamaskChainId,
  getMetamaskProvider,
  getNetworkById,
  sagaExceptionHandler,
} from 'utils';
import { toast } from 'react-toastify';
import { walletSetState } from '../actionCreators';
import { walletSelectors } from '../selectors';
import { WalletActionType } from '../actionTypes';

let metamaskProvider: MetaMaskInpageProvider;

let metamaskProviderChannel: EventChannel<unknown> | undefined;

enum ToastMessage {
  accountChanged = 'Account changed',
  notSupported = 'The app is not supported on this network. Use Polygon',
  notInstalled = 'Please install the MetaMask extension',
}

function closeExistingMetamaskProviderChannel() {
  if (metamaskProviderChannel !== undefined) {
    metamaskProviderChannel.close();
    metamaskProviderChannel = undefined;
  }
}

function* handleMetamskProviderEvents({
  event,
  chainId,
  newAddresses,
}: { event: Web3Event, newAddresses: string[], chainId?: string }) {
  try {
    if (event === Web3Event.chainChanged) {
      yield put(walletSetState({
        status: WalletStatus.LOADING,
      }));

      const network = getNetworkById(chainId);
      if (network) {
        yield put(walletSetState({
          status: WalletStatus.CONNECTED,
          network,
        }));
      } else {
        yield put(walletSetState({ status: WalletStatus.NOT_SUPPORT }));
        toast.error(ToastMessage.notSupported);
      }
    }

    if (event === Web3Event.accountsChanged) {
      if (newAddresses && newAddresses.length !== 0) {
        const address = newAddresses[0];

        yield put(walletSetState({
          status: WalletStatus.CONNECTED,
          address,
        }));
        toast.success(ToastMessage.accountChanged);
      } else {
        yield put(walletSetState({
          status: WalletStatus.LOST,
        }));
      }
    }
  } catch (err) {
    sagaExceptionHandler(err);
  }
}

function* watchMetamaskProviderChannel() {
  closeExistingMetamaskProviderChannel();

  metamaskProviderChannel = eventChannel((emit) => {
    const accountChangeHandler = (...args: unknown[]) => {
      emit({
        event: Web3Event.accountsChanged,
        newAddresses: [...args][0],
      });
    };

    const disconnectHandler = () => {
      emit({
        event: Web3Event.disconnect,
      });
    };

    const changeNetwork = (...args: unknown[]) => {
      emit({
        event: Web3Event.chainChanged,
        chainId: args[0],
      });
    };

    metamaskProvider.on(Web3Event.accountsChanged, accountChangeHandler);
    metamaskProvider.on(Web3Event.disconnect, disconnectHandler);
    metamaskProvider.on(Web3Event.chainChanged, changeNetwork);

    return () => {};
  });

  yield takeLatest(metamaskProviderChannel, handleMetamskProviderEvents);
}

export function* connectMetamaskSaga() {
  try {
    yield put(walletSetState({
      status: WalletStatus.LOADING,
    }));
    metamaskProvider = yield getMetamaskProvider();
    if (!metamaskProvider || !metamaskProvider.isMetaMask) {
      yield put(walletSetState({ status: WalletStatus.LOST }));
      toast.error(ToastMessage.notInstalled);
    }
    if (metamaskProvider) {
      const addresses: string[] = yield metamaskProvider.request({
        method: MetamaskRequestMethod.eth_requestAccounts,
      });

      yield spawn(watchMetamaskProviderChannel);

      if (!addresses.length) {
        yield put(walletSetState({
          status: WalletStatus.NOT_AVAILABLE,
        }));
        return;
      }
      const networkId: Unwrap<typeof getMetamaskChainId> = yield call(getMetamaskChainId);
      const network: NetworkName | null = getNetworkById(networkId);

      if (network) {
        const status: WalletStatus = yield select(walletSelectors.getProp('status'));
        if (status === WalletStatus.LOST) {
          return;
        }
        yield put(walletSetState({
          status: WalletStatus.CONNECTED,
          address: addresses[0],
        }));
      } else {
        yield put(walletSetState({
          status: WalletStatus.NOT_SUPPORT,
        }));
        toast.error(ToastMessage.notSupported);
      }
    }
  } catch (err) {
    yield put(walletSetState({
      status: WalletStatus.NOT_AVAILABLE,
    }));
    sagaExceptionHandler(err);
  }
}

export function* disconnectMetamaskSaga() {
  try {
    const provider: MetaMaskInpageProvider = yield call(detectEthereumProvider);
    let updatedStatus = WalletStatus.INIT;

    if (!provider || !provider.isMetaMask) updatedStatus = WalletStatus.NOT_AVAILABLE;

    yield put(walletSetState({
      status: updatedStatus,
      address: undefined,
    }));

    closeExistingMetamaskProviderChannel();
  } catch (err) {
    sagaExceptionHandler(err);
  }
}

export function* onAppMountSaga() {
  try {
    const address: WalletState['address'] = yield select(walletSelectors.getProp('address'));
    metamaskProvider = yield getMetamaskProvider();

    if (address !== undefined && metamaskProvider) {
      yield spawn(watchMetamaskProviderChannel);
    }
  } catch (err) {
    sagaExceptionHandler(err);
  }
}

export function* metamaskSagas() {
  yield takeLatest(WalletActionType.ON_APP_MOUNT, onAppMountSaga);
  yield takeLatest(WalletActionType.CONNECT, connectMetamaskSaga);
  yield takeLatest(WalletActionType.DISCONNECT, disconnectMetamaskSaga);
}
