import React, { useCallback, memo } from 'react';
import { Link } from 'react-router-dom';
import { PathName, strings, tokenPurchaseUrl } from 'global';
import styles from './styles.module.scss';

const MobileButtons = memo(() => {
  const onPurchaseClick = useCallback(() => window.open(tokenPurchaseUrl), []);
  return (
    <div className={styles.buttons}>
      <Link
        to={PathName.stake}
        className={styles.stakeButton}
      >
        {strings.stakeButtonUpper}
      </Link>

      <div
        onClick={onPurchaseClick}
        className={styles.purchaseButton}
        onKeyPress={undefined}
        role="button"
        tabIndex={0}
      >
        {strings.purchaseButtonUpper}
      </div>
    </div>
  );
});

export { MobileButtons };
