import React from 'react';

const TwitterIcon = ({ className = '' }) => (
  <svg
    id="Component_16_2"
    data-name="Component 16 – 2"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="30"
    height="30"
    viewBox="0 0 30 30"
    className={className}
  >
    <defs>
      <clipPath id="clip-path">
        <rect
          id="Rectangle_2"
          data-name="Rectangle 2"
          width="30"
          height="30"
          fill="#9e9e9e"
        />
      </clipPath>
    </defs>
    <g
      id="Mask_Group_2"
      data-name="Mask Group 2"
      clipPath="url(#clip-path)"
    >
      <path
        id="twitter"
        d="M15,30A15,15,0,1,0,0,15,15,15,0,0,0,15,30Zm6.969-17.379A9.972,9.972,0,0,1,11.754,22.5v0a10.42,10.42,0,0,1-5.5-1.557A7.363,7.363,0,0,0,11.564,19.5a3.677,3.677,0,0,1-2.077-.685,3.481,3.481,0,0,1-1.277-1.725,3.716,3.716,0,0,0,1.621-.06,3.614,3.614,0,0,1-2.067-1.2,3.4,3.4,0,0,1-.813-2.2v-.044a3.664,3.664,0,0,0,1.63.434,3.475,3.475,0,0,1-1.505-2.1,3.375,3.375,0,0,1,.392-2.53,10.138,10.138,0,0,0,3.3,2.575,10.481,10.481,0,0,0,4.1,1.052,3.369,3.369,0,0,1,.226-2.221,3.525,3.525,0,0,1,1.577-1.629A3.7,3.7,0,0,1,18.949,8.8a3.634,3.634,0,0,1,2.038,1.048A7.345,7.345,0,0,0,23.266,9a3.506,3.506,0,0,1-1.579,1.921,7.357,7.357,0,0,0,2.063-.548,7.175,7.175,0,0,1-1.791,1.8c.01.15.01.3.01.45Z"
        fill="#9e9e9e"
        fillRule="evenodd"
      />
    </g>
  </svg>
);

const FacebookIcon = ({ className = '' }) => (
  <svg
    id="facebook_facebook52"
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
    className={className}
  >
    <path
      id="facebook_facebook52-2"
      data-name="facebook_facebook52"
      d="M30,15A15,15,0,1,0,15,30,15,15,0,0,0,30,15ZM10.972,15V12.13h1.759V10.394c0-2.341.7-4.028,3.263-4.028h3.049V9.229H16.9c-1.076,0-1.32.715-1.32,1.463V12.13h3.308L18.434,15H15.577v8.658H12.731V15Z"
      fill="#9e9e9e"
    />
  </svg>
);

const TelegramIcon = ({ className = '' }) => (
  <svg
    id="Component_14_1"
    data-name="Component 14 – 1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="30"
    height="30"
    viewBox="0 0 30 30"
    className={className}
  >
    <defs>
      <clipPath id="clip-path">
        <rect
          id="Rectangle_4"
          data-name="Rectangle 4"
          width="30"
          height="30"
          fill="#9e9e9e"
        />
      </clipPath>
    </defs>
    <g
      id="Mask_Group_3"
      data-name="Mask Group 3"
      clipPath="url(#clip-path)"
    >
      <path
        id="telegram_2_"
        data-name="telegram (2)"
        d="M15.323.323a15,15,0,1,0,15,15A15,15,0,0,0,15.323.323ZM22.69,10.6,20.228,22.2c-.181.823-.671,1.022-1.355.635l-3.75-2.764-1.808,1.742a.947.947,0,0,1-.756.369l.266-3.817,6.95-6.278c.3-.266-.067-.417-.466-.151L10.72,17.343l-3.7-1.155c-.8-.254-.823-.8.169-1.192L21.649,9.419C22.321,9.177,22.907,9.583,22.69,10.6Z"
        transform="translate(-0.323 -0.323)"
        fill="#9e9e9e"
      />
    </g>
  </svg>
);

export {
  FacebookIcon,
  TelegramIcon,
  TwitterIcon,
};
