import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Table } from 'antd';
import {
  Button,
  TextInput,
  Select,
  Text,
} from '@project/libs/components';
import { ModalContext } from '@project/libs/context';
import { strings, Color } from 'global';
import { getFormattedDateDaysLater, formatAmountToCurrency } from 'utils';
import { SelectOption } from '@project/libs/types';
import { useDispatch, useSelector } from 'react-redux';
import { walletSelectors } from 'store/wallet/selectors';
import { walletGetTokensBalance, walletStakeDeposit } from 'store/wallet/actionCreators';
import { toast } from 'react-toastify';
import { ModalCongratulations } from '../ModalCongratulations';
import {
  columns,
  options,
  StakeSelectValue,
  daysSelectedQuantity,
} from '../constants';
import styles from './styles.module.scss';

const StakeCard = memo(() => {
  const dispatch = useDispatch();

  const [fundsError, setFundsError] = useState('');
  const [amountValue, setAmountValue] = useState('');
  const [selectedPeriod, setSelectedPeriod] =
    useState<SelectOption<StakeSelectValue>>();

  const tokensBalance = useSelector(walletSelectors.getProp('tokensBalance'));
  const address = useSelector(walletSelectors.getProp('address'));
  const isStakingInProcess = useSelector(walletSelectors.getProp('isStakingInProcess'));

  useEffect(() => {
    dispatch(walletGetTokensBalance());
  }, [dispatch, address]);

  const balanceFormatted = useMemo(
    () => `${formatAmountToCurrency(tokensBalance)} Tokens`,
    [tokensBalance],
  );

  const stakeUntilDateFormatted = useMemo(() => {
    if (selectedPeriod) {
      return getFormattedDateDaysLater(daysSelectedQuantity[selectedPeriod.value]);
    }
  }, [selectedPeriod]);

  const { openModal } = useContext(ModalContext);

  const onSuccessDepositCallback = useCallback((
    amount: string,
    stakingPeriod: StakeSelectValue,
  ) => {
    openModal(
      <ModalCongratulations
        amount={amount}
        stakingPeriod={stakingPeriod}
      />,
    );
  }, [openModal]);

  const onStakeClick = useCallback(() => {
    if (Number(amountValue) === 0) {
      toast.error(strings.youShouldSelectAmountBeforeStaking);
      return;
    }
    if (Number(amountValue) <= Number(tokensBalance)) {
      if (selectedPeriod !== undefined) {
        const stakingPeriod = selectedPeriod.value;
        dispatch(walletStakeDeposit({
          amount: amountValue,
          stakingPeriod,
          callback: () => onSuccessDepositCallback(amountValue, stakingPeriod),
        }));
      } else {
        toast.error(strings.youShouldSelectPeriodBeforeStaking);
      }
    } else {
      setFundsError(strings.fundsErrorMessage);
    }
  }, [
    amountValue,
    dispatch,
    onSuccessDepositCallback,
    selectedPeriod,
    tokensBalance,
  ]);

  const onChange = useCallback((lockPeriod: SelectOption<StakeSelectValue>) => {
    setSelectedPeriod(lockPeriod);
  }, []);
  const onChangeTextInput = useCallback(
    (inputValue: string) => {
      setFundsError('');
      setAmountValue(inputValue);
    },
    [],
  );
  return (
    <div className={styles.stake_container}>
      <div className={styles.header_container}>
        <Text
          type="h1"
          className={styles.header_container_title}
        >
          {strings.stake}
        </Text>
        <Text
          type="p"
          className={styles.header_balance_container}
        >
          {strings.balance}
          <Text type="span">
            {balanceFormatted}
          </Text>
        </Text>
      </div>

      <div className={styles.stake_body_container}>
        <div className={styles.stake_body_container_inputs}>
          <TextInput
            isNumberOnly
            label={strings.Amount}
            placeholder={strings.ex_1000}
            value={amountValue}
            onChangeValue={onChangeTextInput}
            classNameContainer={styles.stake_container_textInput}
          />
          <div className={styles.stake_wrapper_select}>
            <Select
              label={strings.lock_period}
              className={styles.stake_container_select}
              onChange={onChange}
              options={options}
              value={selectedPeriod}
              placeHolder={strings.select_period}
            />
            {selectedPeriod && (
              <Text
                type="p"
                className={styles.stake_until_container}
              >
                {strings.stakeUntil}
                <Text
                  type="span"
                  className={styles.date}
                >
                  {stakeUntilDateFormatted}
                </Text>
              </Text>
            )}
            {fundsError !== '' && (
              <Text
                type="p"
                className={styles.error}
              >
                {fundsError}
              </Text>
            )}
          </div>

          <Button
            isLoading={isStakingInProcess}
            onClick={onStakeClick}
            edgingColor={Color.grey}
            className={styles.stake_container_button}
          >
            {strings.stake}
          </Button>
        </div>
        <div className={styles.stake_container_table}>
          <Table
            dataSource={options}
            columns={columns}
            pagination={false}
          />
        </div>

        <ul className={styles.stake_container_list}>
          <li>{strings.unstake_before}</li>
          <li>{strings.unstake_penalty}</li>
          <li>{strings.rewards_claiming}</li>
        </ul>
      </div>
    </div>
  );
});
export { StakeCard };
