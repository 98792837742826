export enum WalletActionType {
  SET_STATE = 'WALLET.SET_STATE',
  CONNECT = 'WALLET.CONNECT',
  DISCONNECT = 'WALLET.DISCONNECT',

  STAKE_DEPOSIT = 'WALLET.STAKE_DEPOSIT',
  WITHDRAW_DEPOSIT = 'WALLET.WITHDRAW_DEPOSIT',
  WITHDRAW_REWARDS = 'WALLET.WITHDRAW_REWARDS',
  GET_USER_STAKES = 'WALLET.GET_USER_STAKES',

  GET_TOKENS_BALANCE = 'WALLET.GET_TOKENS_BALANCE',

  ON_APP_MOUNT = 'WALLET.ON_APP_MOUNT',
}
