import React, { memo, useCallback } from 'react';
import cx from 'classnames';
import { useDispatch } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';

import { strings, PathName, Color } from 'global';
import { walletConnect, walletDisconnect } from 'store/wallet/actionCreators';

import { gaiaIcon, logoutIcon } from '@project/libs/assets/images';
import {
  Button,
  Image,
  Text,
} from '@project/libs/components';

import { useWallet } from 'hooks';
import { NavigationButton } from './NavigationButton';
import styles from './styles.module.scss';

const HeaderContainer = memo(() => {
  const dispatch = useDispatch();

  const { isWalletConnected, address } = useWallet();

  const onWalletConnectClick = useCallback(() => {
    dispatch(walletConnect());
  }, [dispatch]);

  const onLogoutClick = useCallback(() => {
    dispatch(walletDisconnect());
  }, [dispatch]);

  const { pathname } = useLocation();

  return (
    <div className={styles.header_container}>
      <Link to={PathName.home}>
        <Image
          url={gaiaIcon}
          className={styles.header_logo}
        />
      </Link>
      <NavigationButton
        title={strings.stakeHeader}
        path={PathName.stake}
        isActive={pathname === PathName.stake}
        className={styles.navigation_button}
      />
      {!isWalletConnected && (
        <Button
          edgingColor={Color.grey}
          className={styles.header__button}
          onClick={onWalletConnectClick}
        >
          {strings.connectWalletButton}
        </Button>
      )}
      {isWalletConnected && (
        <Button
          color={Color.black6}
          edgingColor={Color.grey}
          className={cx(styles.header__button, styles.logout__button)}
          onClick={onLogoutClick}
        >
          <Text
            type="span"
            className={styles.address}
          >
            {address}
          </Text>
          <Image
            url={logoutIcon}
            className={styles.logout_icon}
          />
        </Button>
      )}
    </div>
  );
});

export { HeaderContainer };
