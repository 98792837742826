import { StakeContainer } from 'containers';
import React, { FC } from 'react';
import styles from './styles.module.scss';

const StakePage: FC = () => (
  <div className={styles.stake_page_container}>
    <StakeContainer />
  </div>
);
export { StakePage };
