import { ethers } from 'ethers';
import {
  hollandAbi,
  hollandStakingAddress,
} from 'global';

export const stakingContract = () => {
  try {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const stakingCONTRACT = new ethers.Contract(
      hollandStakingAddress,
      hollandAbi,
      provider.getSigner(),
    );
    return stakingCONTRACT;
  } catch (error) {
    throw new Error('Contract unavaliable');
  }
};
