/* eslint-disable react/jsx-no-useless-fragment */
import React, { forwardRef, memo } from 'react';
import cx from 'classnames';
import { useHoverEvent } from '@project/libs/hooks';
import { Color } from 'global';
import { Loader } from '../Loader';
import {
  ButtonProps,
  ButtonRef,
} from './types';
import styles from './styles.module.scss';

const Button = memo(forwardRef((
  {
    color = Color.blue,
    edgingColor,
    isFullWidth,
    onClick,
    className,
    edgingClassName,
    children,
    disabled,
    isLoading,
    withoutOuterBorder,
    isSimple,
  }: ButtonProps,
  ref: ButtonRef,
) => {
  const { onMouseEnter, onMouseLeave } = useHoverEvent();

  const backgroundStyle = { backgroundColor: color };
  const finalEdgingColor = (withoutOuterBorder || isSimple) ? color : edgingColor;
  const edgingBackgroundStyle = { backgroundColor: finalEdgingColor };

  const isBlueTitle = color === Color.grey;

  const polygonStyle = {
    [styles.polygon_edging]: !isSimple,
  };

  return (
    <button
      ref={ref}
      type="button"
      className={cx(
        styles.button,
        {
          [styles.full_width]: isFullWidth,
        },
        polygonStyle,
        {
          [styles.borders_rounded]: isSimple,
        },
        {
          [styles.disabled]: disabled,
        },
        className,
      )}
      onClick={onClick}
      disabled={disabled || isLoading}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={backgroundStyle}
    >
      <div
        className={cx(
          styles.button_edging,
          polygonStyle,
          edgingClassName,
        )}
        style={edgingBackgroundStyle}
      >
        <div
          className={cx(
            styles.button_content,
            {
              [styles.blue_title]: isBlueTitle,
            },
            polygonStyle,
          )}
          style={backgroundStyle}
        >
          {isLoading ? <Loader /> : <>{children}</>}
        </div>
      </div>
    </button>
  );
}));

export { Button };
