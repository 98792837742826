import React, {
  memo,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import { strings, Color } from 'global';
import { Button, Text, Image } from '@project/libs/components';
import { ModalContext } from '@project/libs/context/modalContext';
import { roundCheckImage } from '@project/libs/assets/images';
import { formatAmountToCurrency } from 'utils';
import styles from './styles.module.scss';
import { selectedPeriodLabels, StakeSelectValue } from '../constants';

type ModalCongratulationsProps = {
  amount: string;
  stakingPeriod: StakeSelectValue;
};

const ModalCongratulations = memo<ModalCongratulationsProps>(({
  amount,
  stakingPeriod,
}) => {
  const { closeModal } = useContext(ModalContext);

  const onOkClick = useCallback(() => {
    closeModal();
  }, [closeModal]);

  const amountFormatted = useMemo(() => formatAmountToCurrency(amount), [amount]);

  return (
    <div className={styles.congratulations_container}>
      <Text
        type="h2"
        className={styles.congratulations_title}
      >
        {strings.congratulations}
      </Text>
      <Image
        url={roundCheckImage}
        className={styles.congratulations_circle_image}
      />
      <Text
        type="h1"
        className={styles.congratulations_tokens_quantity}
      >
        {amountFormatted}
      </Text>
      <Text
        type="span"
        className={styles.congratulations_description}
      >
        {strings.tokensWereSuccessfullyStakedFor}
      </Text>
      <Text
        type="span"
        className={styles.congratulations_years}
      >
        {selectedPeriodLabels[stakingPeriod]}
      </Text>
      <Button
        edgingColor={Color.black5}
        className={styles.congratulations_button}
        onClick={onOkClick}
      >
        {strings.ok}
      </Button>
    </div>
  );
});

export { ModalCongratulations };
