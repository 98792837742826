import {
  hollandStakingAddress,
} from 'global';
import { stakingContract, tokenContract } from 'api';
import { ethers, ContractTransaction } from 'ethers';

export const approveDeposit = async (
  stakeAmount: string,
) => {
  const contract = tokenContract();
  const tx:ContractTransaction = await contract.approve(
    hollandStakingAddress,
    stakeAmount,
  );
  await tx.wait();
};

export const createDeposit = async (
  amount: string,
  stakingPeriod: number,
) => {
  const staking = stakingContract();
  const tx:ContractTransaction = await staking.createDeposit(
    amount,
    stakingPeriod,
  );

  await tx.wait();
};

export const withdrawDeposit = async (
  index: number,
) => {
  const staking = stakingContract();
  const tx:ContractTransaction = await staking.withdrawDeposit(index);
  await tx.wait();
};

export const withdrawRewards = async (
  index: number,
) => {
  const staking = stakingContract();
  const tx:ContractTransaction = await staking.withdrawRewards(index);
  await tx.wait();

  await tx.wait();
};
export const getTokensBalance = async (
  addressWallet: string,
) => {
  const contract = tokenContract();
  const balance:ethers.BigNumber = await contract.balanceOf(addressWallet);
  return balance.toString();
};
