import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import web3 from 'web3';
import {
  StakeStatus,
  Unwrap,
  UserStake,
  UserStakeRaw,
} from 'types';
import {
  getAddress,
  sagaExceptionHandler,
} from 'utils';
import { toast } from 'react-toastify';
import { callApi } from 'api';
import {
  approveDeposit,
  createDeposit,
  withdrawDeposit,
  withdrawRewards,
} from '../../../api/etherium';
import {
  walletGetTokensBalance,
  walletGetUserStakes,
  walletSetState,
  walletStakeDeposit,
  walletWithdrawDeposit,
  walletWithdrawRewards,
} from '../actionCreators';
import { WalletActionType } from '../actionTypes';

const formatWei = (wei: string | number) => {
  const weiStringValue = typeof wei === 'number' ? wei.toLocaleString('fullwide', { useGrouping: false }) : wei;

  return Math.floor(Number(web3.utils.fromWei(weiStringValue)));
};

export function* stakeDepositSaga({ payload }: ReturnType<typeof walletStakeDeposit>) {
  try {
    yield put(walletSetState({ isStakingInProcess: true }));

    const address: Unwrap<typeof getAddress> = yield getAddress();

    if (address) {
      const { amount, stakingPeriod, callback } = payload;

      const amountWei = web3.utils.toWei(String(amount));
      yield approveDeposit(amountWei);
      toast.success('Success approve');

      yield createDeposit(
        amountWei,
        stakingPeriod,
      );

      if (callback !== undefined) {
        callback();
      }

      yield put(walletGetTokensBalance());
    }
  } catch (err) {
    sagaExceptionHandler(err);
  } finally {
    yield put(walletSetState({ isStakingInProcess: false }));
  }
}

export function* withdrawDepositSaga({ payload }: ReturnType<typeof walletWithdrawDeposit>) {
  try {
    yield put(walletSetState({ isUnstaking: true }));

    const address: Unwrap<typeof getAddress> = yield getAddress();

    if (address) {
      const { stakeIndex, callback } = payload;

      yield withdrawDeposit(stakeIndex);

      if (callback !== undefined) {
        callback();
      }

      yield put(walletGetTokensBalance());

      toast.success('Success unstake');
    }
  } catch (err) {
    Reactotron.log('error at withdraw', err);
    sagaExceptionHandler(err);
  } finally {
    yield put(walletSetState({ isUnstaking: false }));
  }
}

export function* withdrawRewardsSaga({ payload }: ReturnType<typeof walletWithdrawRewards>) {
  try {
    yield put(walletSetState({ isWithdrawingRewards: true }));

    const address: Unwrap<typeof getAddress> = yield getAddress();

    if (address) {
      const { stakeIndex, callback } = payload;

      yield withdrawRewards(stakeIndex);

      if (callback !== undefined) {
        callback();
      }

      yield put(walletGetTokensBalance());

      toast.success('Success Withdraw rewards');
    }
  } catch (err) {
    Reactotron.log('error at withdraw', err);
    sagaExceptionHandler(err);
  } finally {
    yield put(walletSetState({ isWithdrawingRewards: false }));
  }
}

export function* getUserStakesSaga({ payload }: ReturnType<typeof walletGetUserStakes>) {
  try {
    const address: Unwrap<typeof getAddress> = yield getAddress();

    yield put(walletSetState({ isUserStakesLoading: true }));
    if (address) {
      const { status } = payload;

      const addressChecksum = web3.utils.toChecksumAddress(address);

      const statusUrl = status !== StakeStatus.combined ? `&stakeStatus=${status}` : '';

      const endpoint = `user-stakes?address=${addressChecksum}${statusUrl}`;

      const userStakesRaw: UserStakeRaw[] = yield call(callApi, {
        endpoint,
      });

      const userStakes: UserStake[] = userStakesRaw.map(({
        id,
        stakeDate,
        dueDate,
        rewardClaimingDate,
        stakeSize,
        stakeIndex,
        rewardAvailable,
        rewardsClaimed,
        totalRewards,
        stakeStatus,
      }) => ({
        id,
        stakeDate,
        dueDate,
        rewardClaimingDate,
        rewardAvailable: formatWei(rewardAvailable),
        rewardsClaimed: formatWei(rewardsClaimed),
        totalRewards: formatWei(totalRewards),
        stakeSize: formatWei(stakeSize),
        stakeIndex,
        stakeStatus,
      }));

      yield put(walletSetState({
        userStakes,
        isUserStakesLoading: false,
      }));
    }
  } catch (err) {
    sagaExceptionHandler(err);
    yield put(walletSetState({
      isUserStakesLoading: false,
    }));
  }
}

export function* stakeSagas() {
  yield takeLatest(WalletActionType.STAKE_DEPOSIT, stakeDepositSaga);
  yield takeLatest(WalletActionType.WITHDRAW_DEPOSIT, withdrawDepositSaga);
  yield takeLatest(WalletActionType.WITHDRAW_REWARDS, withdrawRewardsSaga);
  yield takeLatest(WalletActionType.GET_USER_STAKES, getUserStakesSaga);
}
