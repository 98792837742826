import React, { memo, useCallback } from 'react';
import cx from 'classnames';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { PathName, strings, tokenPurchaseUrl } from 'global';
import { useWallet } from 'hooks';
import { images } from '@project/libs/assets/images';
import { Image } from '@project/libs/components';
import { walletConnect } from 'store/wallet/actionCreators';
import { StakeBlock } from './StakeBlock';
import { TokenContractInfo } from './TokenContractInfo';
import styles from './styles.module.scss';

const GreetingsContainer = memo(() => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isWalletConnected } = useWallet();

  const onPurchaseClick = useCallback(() => window.open(tokenPurchaseUrl), []);

  const onStakeClick = useCallback(() => {
    if (!isWalletConnected) {
      dispatch(walletConnect());
    }
    navigate(PathName.stake);
  }, [dispatch, isWalletConnected, navigate]);

  return (
    <div className={cx(styles.greetings)}>
      <Image
        url={images.turtels}
        className={cx(styles.greetings__turtelsImage)}
      />

      <div className={styles.greetings__block_wrapper}>
        <Image
          url={images.turtels}
          className={cx(styles.greetings__turtels_mobileImage)}
        />

        <StakeBlock
          className={styles.greetings__stakeBlock}
          img={images.chest}
          title={strings.stakeTitle}
          description={strings.stakeDescription}
          buttonTitle={strings.stakeButton}
          onClick={onStakeClick}
        />

        <StakeBlock
          className={styles.greetings__stakeBlock2}
          img={images.giaToken}
          title={strings.purchaseTitle}
          description={strings.purchaseDescription}
          buttonTitle={strings.purchaseButton}
          onClick={onPurchaseClick}
        />

        <TokenContractInfo />
      </div>
    </div>
  );
});

export { GreetingsContainer };
