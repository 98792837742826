import { keys } from 'ramda';
import {
  RadioButtonOption,
  SelectOption,
  StakeStatus,
} from 'types';
import { strings } from 'global';

export const myStakeRadioButtonsOptions: RadioButtonOption<StakeStatus>[] =
  [
    { value: StakeStatus.active, label: strings.Active },
    { value: StakeStatus.unstaked, label: strings.Unstake },
    { value: StakeStatus.combined, label: strings.Both },
  ];

export enum StakeSelectValue {
  Month1 = 1,
  Month2 = 2,
  Year1 = 3,
  Year2 = 4,
  Year3 = 5,
  Year4 = 6,
  Year5 = 7,
}

export const selectedPeriodLabels = {
  [StakeSelectValue.Month1]: '1 month',
  [StakeSelectValue.Month2]: '2 months',
  [StakeSelectValue.Year1]: '1 year',
  [StakeSelectValue.Year2]: '2 years',
  [StakeSelectValue.Year3]: '3 years',
  [StakeSelectValue.Year4]: '4 years',
  [StakeSelectValue.Year5]: '5 years',
};

export const selectedPeriodRewards = {
  [StakeSelectValue.Month1]: '5%',
  [StakeSelectValue.Month2]: '15%',
  [StakeSelectValue.Year1]: '90%',
  [StakeSelectValue.Year2]: '160%',
  [StakeSelectValue.Year3]: '260%',
  [StakeSelectValue.Year4]: '410%',
  [StakeSelectValue.Year5]: '650%',
};

export const daysSelectedQuantity = {
  [StakeSelectValue.Month1]: 30,
  [StakeSelectValue.Month2]: 60,
  [StakeSelectValue.Year1]: 365,
  [StakeSelectValue.Year2]: 365 * 2,
  [StakeSelectValue.Year3]: 365 * 3,
  [StakeSelectValue.Year4]: 365 * 4,
  [StakeSelectValue.Year5]: 365 * 5,
};

const isNumber = (value: string | number) => Number.isNaN(Number(value));

export const options: SelectOption<StakeSelectValue>[] = (
  keys(StakeSelectValue).filter(isNumber).map((key) => ({
    value: StakeSelectValue[key],
    key: StakeSelectValue[key],
    label: selectedPeriodLabels[StakeSelectValue[key]],
    rewards: selectedPeriodRewards[StakeSelectValue[key]],
  })));

export const columns = [
  {
    title: strings.lock_period,
    dataIndex: 'label',
    key: 'label',
  },
  {
    title: strings.rewards,
    dataIndex: 'rewards',
    key: 'rewards',
  },
];
