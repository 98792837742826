import {
  put,
  takeLatest,
} from 'redux-saga/effects';
import web3 from 'web3';
import { getTokensBalance } from 'api/etherium';

import {
  getAddress,
  sagaExceptionHandler,
} from 'utils';
import { Unwrap } from 'types';
import { walletSetState } from '../actionCreators';
import { WalletActionType } from '../actionTypes';

export function* getTokensBalanceSaga() {
  try {
    yield put(walletSetState({ isTokensBalanceLoading: true }));

    const address: Unwrap<typeof getAddress> = yield getAddress();

    if (address) {
      const balanceWei: string = yield getTokensBalance(address);

      const tokensBalance = web3.utils.fromWei(balanceWei);

      yield put(walletSetState({ tokensBalance }));
    }
  } catch (err) {
    sagaExceptionHandler(err);
  } finally {
    yield put(walletSetState({ isTokensBalanceLoading: false }));
  }
}

export function* balanceSagas() {
  yield takeLatest(WalletActionType.GET_TOKENS_BALANCE, getTokensBalanceSaga);
}
