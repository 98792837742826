import React from 'react';
import { Text } from '@project/libs/components';
import { hollandStakingAddress, strings, tokenAddress } from 'global';
import styles from './styles.module.scss';
import { stakingContractLink, tokenContractLink } from './tokenLink';

const TokenContractInfo = () => (
  <div className={styles.block}>
    <Text
      type="p"
      className={styles.block__label}
    >
      {strings.informationOfOurSmart}
    </Text>
    <Text type="p">
      {strings.tokenContract}

      <a
        href={tokenContractLink}
        target="_blank"
        rel="noreferrer"
        className={styles.address}
      >
        {tokenAddress}
      </a>

    </Text>

    <Text type="p">
      {strings.stakingContract}
      {' '}
      <a
        href={stakingContractLink}
        target="_blank"
        rel="noreferrer"
        className={styles.address}
      >
        {hollandStakingAddress}
      </a>
    </Text>
  </div>
);
export { TokenContractInfo };
