import { useSelector } from 'react-redux';
import { walletSelectors } from 'store/wallet/selectors';
import { WalletStatus } from 'types';

export const useWallet = () => {
  const {
    status,
    address,
  } = useSelector(walletSelectors.getState);

  const isWalletConnected = status === WalletStatus.CONNECTED && address !== undefined;

  return {
    isWalletConnected,
    status,
    address,
  };
};
