import {
  addDays,
  format,
  intervalToDuration,
} from 'date-fns';
import moment from 'moment';

export const formatDate = (date: Date | string | number) => {
  const dateToformat = new Date(date);
  return format(dateToformat, 'MMM d, yyyy, h:mm:ss');
};

const formatDigit = (digit: number | undefined) => {
  if (!digit) {
    return '00';
  }
  return (digit).toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });
};

const formatedtimeCountdown = (
  days?:number,
  hours?:number,
  minutes?:number,
  seconds?:number,
) => {
  const daysFormatted = days !== undefined && days > 0 ? `${formatDigit(days)} days ` : '';
  return `${daysFormatted}${formatDigit(hours)}:${formatDigit(minutes)}:${formatDigit(seconds)}`;
};

export const formatTimeCountdown = (date: number | string) => {
  const {
    days,
    hours,
    minutes,
    seconds,
  } = intervalToDuration({
    start: new Date(date),
    end: new Date(),
  });

  const timeCountdown = formatedtimeCountdown(
    days,
    hours,
    minutes,
    seconds,
  );

  return {
    timeCountdown,
  };
};

export const getFormattedDateDaysLater = (days: number) =>
  formatDate(addDays(new Date(), days));

export const formatAmountToCurrency = (value: number | string | undefined) => {
  if (!value) {
    return '0';
  }
  const numberValue = typeof value === 'string' ? Number(value) : value;

  return Math.floor(numberValue).toLocaleString();
};

export const differenceBetweenDate = (date:number | string) => {
  const {
    hours,
    minutes,
    seconds,
  } = intervalToDuration({
    start: new Date(date),
    end: new Date(),
  });
  const now = new Date();
  const future = new Date(date);
  const differencesDays = moment(future).diff(moment(now), 'day');
  const timeCountdown = formatedtimeCountdown(
    differencesDays,
    hours,
    minutes,
    seconds,
  );
  return timeCountdown;
};
