import React, {
  FC,
  useMemo,
} from 'react';
import { Tabs } from '@project/libs/components';
import { strings, ScreenWidth } from 'global';
import { useScreenWidth } from 'hooks';
import { StakeCard } from './StakeCard';
import { MyStakeCard } from './MyStakeCard';
import styles from './styles.module.scss';

const StakeContainer: FC = () => {
  const tabContents = useMemo(() => [<StakeCard />, <MyStakeCard />], []);
  const isMobile = useScreenWidth(ScreenWidth.mobile);
  return (
    <div className={styles.stake_container}>
      {!isMobile && (
        <div className={styles.stake_cards_container}>
          <StakeCard />
          <MyStakeCard />
        </div>
      )}
      {isMobile && (
        <Tabs
          tabNames={[strings.STAKE, strings.MY_STAKE]}
          tabContents={tabContents}
          className={styles.tabs}
        />
      )}
    </div>
  );
};

export { StakeContainer };
