import React, { FC } from 'react';
import { GreetingsContainer } from 'containers';
import { MobileButtons } from './MobileButtons';
import styles from './styles.module.scss';

const HomePage: FC = () => (
  <div className={styles.home_page_conatiner}>
    <GreetingsContainer />
    <MobileButtons />
  </div>
);

export { HomePage };
