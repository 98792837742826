import React, { FC } from 'react';
import {
  FacebookIcon,
  TelegramIcon,
  TwitterIcon,
} from '@project/libs/assets/images';
import { Text } from '@project/libs/components';
import { strings } from 'global';
import { links } from './links';
import styles from './styles.module.scss';

const Footer: FC = () => (
  <div className={styles.footer}>
    <Text
      type="p"
      className={styles.description}
    >
      {strings.copyRight}
    </Text>
    <div className={styles.social}>
      <Text
        type="p"
        className={styles.title}
      >
        {strings.socials}
      </Text>
      <div className={styles.socialLink_container}>
        <a
          href={links.twitter}
          target="_blank"
          className={styles.socialLink}
          rel="noreferrer"
        >
          <TwitterIcon className={styles.socialIcon} />
        </a>
        <a
          href={links.facebook}
          target="_blank"
          className={styles.socialLink}
          rel="noreferrer"
        >
          <FacebookIcon className={styles.socialIcon} />
        </a>
        <a
          href={links.telegram}
          target="_blank"
          className={styles.socialLink}
          rel="noreferrer"
        >
          <TelegramIcon className={styles.socialIcon} />
        </a>
      </div>
    </div>
  </div>
);

export { Footer };
