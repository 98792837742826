export const strings = {
  stakeHeader: 'STAKE',
  purchaseButton: 'Purchase',
  purchaseButtonUpper: 'PURCHASE',
  stakeButton: 'Stake',
  stakeButtonUpper: 'STAKE',
  connectWalletButton: 'Connect Wallet',
  unstakeButton: 'Unstake',
  okButton: 'ok',
  cancelButton: 'Cancel',
  confirmButton: 'Confirm',
  applyConfirm: 'Apply',
  claimRewards: 'Claim Rewards',
  desktop: 'Desktop',
  mobile: 'Mobile',
  copyRight: 'Gaia Everworld. Copyright 2021. All rights reserved.',
  socials: 'Socials',
  stakeTitle: 'STAKE TOKENS AND GET REWARDS!',
  purchaseTitle: 'DO NOT HAVE THE TOKENS YET?',
  stakeDescription:
    'Staking is a way for us to reward our community for their long term vision. By staking you’ll earn rewards of more $GAIA. In the future, staking $GAIA will give you voting rights over future development of the project.',
  purchaseDescription:
    'To stake your $GAIA you must first own some. You can acquire your $GAIA tokens via MEXC, Gate.io, quickswap or directly from here.',
  informationOfOurSmart:
    'Your can obtain the information of our smart contracts here:',
  tokenContract: 'Token contract: ',
  stakingContract: 'Staking contract: ',
  balance: 'Balance ',
  stake: 'Stake',
  myStakes: 'My Stakes',

  congratulations: 'Congratulations',
  tokensWereSuccessfullyStakedFor: 'tokens were successfully staked for',
  year: 'year.',
  ok: 'OK',

  stakedTokens: 'Staked Tokens',
  rewardsAvailable: 'Rewards Available',
  penaltySize: 'Penalty Size',
  youWillReceive: 'You will receive',
  unstakeConfirmation: 'Unstake Confirmation',
  warning: 'Warning!',
  youAreUnstakingTheActivePosition: 'You are unstaking the active position.',
  congratulationnsTheLockPeriodIsOver: 'Congratulations! The lock period for this position is over, you can withdraw your tokens and rewards without any penalty!',
  theStakingWillBeCompletedOnlyIn: 'The staking will be completed only in',
  days: 'days',
  unstakingYourPositionNowWillRequireThe:
    'Unstaking your position now will require the',
  penaltyPayment: 'penalty payment.',
  cancel: 'Cancel',
  confirm: 'Confirm',
  lock_period: 'Lock Period',
  connect_your_wallet: 'Connect your wallet to stake or view transactions.',
  unstake_before:
    'Unstaking before the determined lock period requires the 75% penalty.',
  unstake_penalty:
    'Unstake penalty only applies to staked amount, not accrued rewards.',
  rewards_claiming: 'Rewards claiming is available once for each 7 days after first month of staking.',
  Active: 'Active',
  Unstake: 'Unstaked',
  Both: 'Both',
  stakeSize: 'Stake Size: ',
  stakeDate: 'Stake Date',
  dueDate: 'Due Date',
  rewardsAvialable: 'Rewards Available for Withdrawal',
  totalRewards: 'Total Rewards Accrued',
  rewardsClaimedEarlier: 'Rewards claimed earlier',
  nextRewards: 'Next rewards claiming is available in',
  stakeUntil: 'Stake Until ',
  rewards: 'Rewards',
  unstake_button: 'Unstake',
  claim_rewards_button: 'Claim Rewards',
  Amount: 'Amount',
  STAKE: 'STAKE',
  MY_STAKE: 'MY STAKES',
  tokens: 'tokens',
  fundsErrorMessage:
    'The funds on the wallet balance are not enough. Please, top it up or specify a less staking amount.',

  youShouldSelectPeriodBeforeStaking: 'You should select period before staking',
  youShouldSelectAmountBeforeStaking: 'You should select Amount before staking',
  select_period: 'Select period',
  ex_1000: 'Ex. 1000',
};
