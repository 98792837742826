export enum Color {
  blue = '#89CFF0',
  green = '#44E991',

  black = '#000000',
  black1 = '#17171A',
  black2 = '#1c1c1e',
  black3 = '#0a0a0c',
  black4 = '#2B2B2E',
  black5 = '#0A0A0C',
  black6 = '#212121',

  white = 'white',

  grey = '#2b2b2e',
  grey2 = '#9E9E9E',
  grey3 = '#363636',
  grey4 = '#464646',
  grey5 = '#252525',
  grey6 = '#1e1e21',

  red = '#FF3333',

  blackTransparent = 'rgba(0, 0, 0, 0.52)',
}
