import React, { memo } from 'react';
import cx from 'classnames';
import { Color } from 'global';
import { Image, Text, Button } from '@project/libs/components';
import styles from './styles.module.scss';

 type StakeBlockProps = {
   img: string;
   title: string;
   description: string;
   buttonTitle: string;
   className?: string;
   onClick:()=> void;
 };

const StakeBlock = memo<StakeBlockProps>(({
  img,
  description,
  title,
  buttonTitle,
  className,
  onClick,
}) => (
  <div className={cx(styles.stakeBlock, className)}>
    <Image
      url={img}
      className={cx(styles.stakeBlock__image)}
    />
    <Text
      type="h1"
      className={styles.stakeBlock__title}
    >
      {title}
    </Text>
    <Text
      type="p"
      className={styles.stakeBlock__description}
    >
      {description}
    </Text>
    <Button
      edgingColor={Color.grey}
      className={styles.stakeBlock__button}
      onClick={onClick}
    >
      {buttonTitle}
    </Button>
  </div>
));

export { StakeBlock };
