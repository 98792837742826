import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { List, ListRowRenderer } from 'react-virtualized';
import { strings, ScreenWidth } from 'global';
import { useWallet, useScreenWidth } from 'hooks';
import { StakeStatus, UserStake } from 'types';
import { walletSelectors } from 'store/wallet/selectors';
import {
  walletGetUserStakes,
  walletWithdrawDeposit,
  walletWithdrawRewards,
} from 'store/wallet/actionCreators';
import { RadioButtons, Text } from '@project/libs/components';

import { ModalContext } from '@project/libs/context';
import { WalletNotConnected } from './WalletNotConnected';
import { MyStakeItem } from './MyStakeItem';
import { myStakeRadioButtonsOptions } from '../constants';
import { ModalUnstakeConfirmation } from '../ModalUnstakeConfirmation';
import styles from './styles.module.scss';

const MyStakeCard = memo(() => {
  const dispatch = useDispatch();

  const { openModal } = useContext(ModalContext);

  const [selectedStakeStatus, setSelectedStakeStatus] = useState(StakeStatus.active);
  const { isWalletConnected } = useWallet();
  const isTablet = useScreenWidth(ScreenWidth.tablet);
  const userStakes = useSelector(walletSelectors.getProp('userStakes'));

  useEffect(() => {
    dispatch(walletGetUserStakes({
      status: selectedStakeStatus,
    }));
  }, [dispatch, selectedStakeStatus]);

  const onWithdrawDeposit = useCallback((stakeIndex: number, callback: () => void) => {
    dispatch(walletWithdrawDeposit({ stakeIndex, callback }));
  }, [dispatch]);

  const onUnstake = useCallback((userStake: UserStake) => {
    openModal(
      <ModalUnstakeConfirmation
        userStake={userStake}
        onWithdrawDeposit={onWithdrawDeposit}
      />,
    );
  }, [openModal, onWithdrawDeposit]);

  const onWithdrawRewards = useCallback((stakeIndex: number) => {
    dispatch(walletWithdrawRewards({ stakeIndex }));
  }, [dispatch]);

  const rowRenderer = useCallback<ListRowRenderer>(
    ({ key, index, style }) => {
      const userStake = userStakes[index];
      return (
        <MyStakeItem
          key={key}
          style={style}
          userStake={userStake}
          onUnstake={onUnstake}
          onWithdrawRewards={onWithdrawRewards}
        />
      );
    },
    [onUnstake, onWithdrawRewards, userStakes],
  );
  return (
    <div className={styles.mystake_container}>
      <div
        className={cx(styles.header_container, {
          [styles.wallet_not_connected]: !isWalletConnected,
        })}
      >
        <Text
          type="h1"
          className={styles.header_container_title}
        >
          {strings.myStakes}
        </Text>
        <RadioButtons
          options={myStakeRadioButtonsOptions}
          onChange={setSelectedStakeStatus}
          disabled={!isWalletConnected}
          currentValue={selectedStakeStatus}
        />
      </div>
      {isWalletConnected && (
        <List
          width={775}
          height={680}
          rowCount={userStakes.length}
          rowHeight={isTablet ? 480 : 320}
          rowRenderer={rowRenderer}
          autoWidth
          className={styles.mystake_body_container}
        />
      )}
      {!isWalletConnected && <WalletNotConnected />}
    </div>
  );
});

export { MyStakeCard };
