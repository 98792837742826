import { ethers } from 'ethers';
import { erc20abi, tokenAddress } from 'global';

export const tokenContract = () => {
  try {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const tokentCONTRACT = new ethers.Contract(tokenAddress, erc20abi, provider.getSigner());
    return tokentCONTRACT;
  } catch (error) {
    throw new Error('Contract unavaliable');
  }
};
