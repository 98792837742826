import React, { memo } from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { PathName } from 'global';
import { Text } from '@project/libs/components';

import styles from './styles.module.scss';

type NavigationButtonProps = {
  title: string;
  path: PathName;
  isActive: boolean;
  className?: string;
};

const NavigationButton = memo<NavigationButtonProps>(({
  title,
  path,
  isActive,
  className,
}) => (
  <Link
    to={path}
    className={cx(
      styles.navigation_button_container,
      { [styles.button_active]: isActive },
      className,
    )}
  >
    <Text
      type="span"
      className={cx(styles.navigation_button_title, {
        [styles.title_active]: isActive,
      })}
    >
      {title}
    </Text>
  </Link>
));

export { NavigationButton };
