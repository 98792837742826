import chest from './chest.png';
import giaToken from './gaiaCoin.png';
import turtels from './turtels.png';
import home from './homePageBackground.png';
import homePageTopLeftImage from './homePageTopLeftImage.png';
import homePageBottomRightImage from './homePageBottomRightImage.png';

export const images = {
  chest,
  giaToken,
  home,
  homePageTopLeftImage,
  homePageBottomRightImage,
  turtels,
};
